<template>
  <div id="bookingPackages" class="booking-form-section booking-packages" :class="{ unlocked: $store.state.booking.unlockedSteps.includes('bookingPackages') }">
    <div class="booking-step-title-wrapper">
      <h4 class="title">Packages</h4>
    </div>

    <div class="content">
      <div id="booking_packages" class="box packages" :class="[packageForm]">
        <div class="inner-wrapper">
          <div class="title-n-list" :class="{}" v-if="$store.state.booking.data.packages.length">
            <!-- <div class="inner-title-wrapper">
              <h5 class="title">Packages</h5>
            </div> -->
        
            <div class="d-flex justify-content-unset align-items-center">
                <!-- <div class="icon" :style="{ 'background-image': 'url(' + packageTypes[$store.state.booking.data.packages[0].type].iconImage + ')' }"></div> -->
                <div class="name">{{ packageTypes[$store.state.booking.data.packages[0].type]?.name }}</div>
            </div>
        
            <div class="row mt-2 mb-4" v-if="$store.state.booking.data.packages.length">
                <div
                    class="col-md-12 align-items-center justify-content-between package"
                    style="display: flex; flex-wrap: wrap;"
                    v-for="(pkg, index) in $store.state.booking.data.packages"
                    :key="index"
                    @click="handleDeleteConfirmBoxClicks"
                >
                    <!-- <div class="col type">
                        <div class="name-n-icon">
                            <div class="icon" :style="{ 'background-image': 'url(' + packageTypes[pkg.type].iconImage + ')' }"></div>
                            <div class="name">{{ packageTypes[pkg.type].name }}</div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-2">
                        <p class="mb-0">Send from <br><strong> {{ addressListDropdown.countryCode }}</strong></p>
                    </div>
                    <div class="col-md-2">
                        <p class="mb-0">Deliver to<br> <strong> {{ destinationAddress.countryCode }}</strong></p>
                    </div> -->
                    <div class="col-md-2">
                        <p class="mb-0">Length <br> <strong>{{ pkg.length }} CM</strong></p>
                    </div>
                    <div class="col-md-2">
                        <p class="mb-0">Width <br> <strong>{{ pkg.width }} CM</strong></p>
                    </div>
                    <div class="col-md-2">
                        <p class="mb-0">Height <br> <strong>{{ pkg.height }} CM</strong></p>
                    </div>
                    <div class="col-md-2">
                        <p class="mb-0">Weight<br> <strong>{{ pkg.weight }} KG</strong></p>
                    </div>
                    
                    <div class="col-md-4" style="text-align: center;margin-top: 16px;">
                      <a
                        href="#"
                        class="btn edit"
                        style="background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); color: #fff; padding: 10px 40px; margin-right: 10px;"
                        @click.prevent="editPackage(index)"
                        >Edit</a
                      >
                        <a
                          href="#"
                          class="btn delete"
                          style="background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); color: #fff; padding: 10px 40px;"
                          @click.prevent="showDeleteConfirmBox($event, index)"
                          >Delete</a
                        >
                    </div>

                  
                </div>
            </div>
          </div>        

          <div class="package-form new-branding-form" :class="packageForm">
            <div class="form-outer-wrapper form-style-1" :class="formState">
              <div class="form-title-wrapper">
                <h5 class="title">Package Details</h5>
                <a href="#" class="close" @click.prevent="packageForm = null"></a>
              </div>
              <div class="form-wrapper">
                <div class="form">
                  <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.type.errorMessage }">
                        <label for="email">Service</label>
                        <div class="input-field">
                          <select name="type" id="add-new-select-package" @change="onPackageChange" placeholder="Type" ref="typeDropdownEl" data-rules='["required"]' v-model="pkg.type"></select>
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.type.errorMessage">
                        {{ validation.type.errorMessage }}
                      </p>
                    </div>

                    <div class="mobile-package-shape">
                      <div class="shape" :class="[shapeHighlight]"></div>
                    </div>

                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.weight.errorMessage }">
                        <label for="weight">Weight </label>
                        <div class="input-field">
                          <!-- data-rules='["required"]' -->
                          <input type="text" name="weight" id="weight"  :data-rules='"[" + "\"required\", \"less:" + maxWeight+ "\"]"' v-model="pkg.weight" />
                          <div class="unit">KG</div>
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.weight.errorMessage">
                        {{ validation.weight.errorMessage }}
                      </p>
                    </div>

                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.value.errorMessage }">
                        <label for="value">Value </label>
                        <div class="input-field">
                          <input type="text" name="value" id="value" :disabled="isDisabled" v-model="pkg.value" data-rules='["required", "numeric"]'/>
                          <div class="unit">AED</div>
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.value.errorMessage">
                        {{ validation.value.errorMessage }}
                      </p>
                    </div>

                    <div class="form-sep l"></div>

                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.length.errorMessage || validation.width.errorMessage || validation.height.errorMessage }">
                        <label for="weight">Dimensions </label>
                        <div class="input-fields-row">
                          <div class="input-field-col">
                            <div class="input-field">
                              <input type="text" name="length" id="length" data-rules='["required"]' v-model="pkg.length"  @focus="highlightShape('length')" @blur="unhighlightShape"/>
                              <div class="unit">CM</div>
                              <div class="caption">Length</div>
                            </div>
                          </div>

                          <div class="input-field-col">
                            <div class="input-field">
                              <input type="text" name="width" id="width" data-rules='["required"]' v-model="pkg.width" @focus="highlightShape('width')" @blur="unhighlightShape"/>
                              <div class="unit">CM</div>
                              <div class="caption">Width</div>
                            </div>
                          </div>

                          <div class="input-field-col">
                            <div class="input-field">
                              <input type="text" name="height" id="height" data-rules='["required"]' v-model="pkg.height"  @focus="highlightShape('height')" @blur="unhighlightShape"/>
                              <div class="unit">CM</div>
                              <div class="caption">Height</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.length.errorMessage || validation.width.errorMessage || validation.height.errorMessage">
                        <span v-if="!validation.width.errorMessage && !validation.height.errorMessage">{{ validation.length.errorMessage }}</span>
                        <span v-if="!validation.length.errorMessage && !validation.height.errorMessage">{{ validation.width.errorMessage }}</span>
                        <span v-if="!validation.width.errorMessage && !validation.length.errorMessage">{{ validation.height.errorMessage }}</span>
                      </p>
                    </div>

                    <div class="form-sep l"></div>

                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.value.errorMessage }">
                        <label for="weight"
                          >Description
                          <span>Optional</span>
                        </label>
                        <div class="input-field">
                          <textarea name="description" id="description" cols="30" rows="10" v-model="pkg.description"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="form-action-group">
                      <button type="submit" class="">Save</button>
                    </div>
                  </form>
                </div>
                <div class="package-shape">
                  <!-- <div class="shape" :class="[shapeHighlight]"></div> -->
                </div>
              </div>
              <div class="loading-overlay">
                <div class="loader"></div>
              </div>
            </div>
          </div>

          <div class="add-new-wrapper">
            <a href="#" class="add-new" @click.prevent="newPackage()">
              <i></i>
              <span>Add another package</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as TomSelect from 'tom-select';
//import { mapGetters } from 'vuex';
import formValidation from '@/mixins/formValidation';

export default {
  name: 'Packages',
  mixins: [formValidation],
  props: {
    addressListDropdown: {required: true },
    destinationAddress: { required: true },
    isNextStep: {
    required: false,
    type: Boolean 
  }
  },
  data() {
    return {
      isDisabled: false,
      showPackageList: false,
      packageForm: null,
      editingPackageIndex: null,
      validation: {
        weight: {},
        type: {},
        value: {},
        length: {},
        width: {},
        height: {},
        description: {},
      },
      pkg: {
        weight: '',
        type: 'small-box',
        value: '',
        length: '',
        width: '',
        height: '',
        description: '',
      },
      packageTypes: {},
      maxWeight: 0,
      shapeHighlight: ''
    };
  },
  mounted() {
    console.log("here chekc next step", this.isNextStep);
    this.initValidation();

    this.$store.dispatch('fetchPackageTypes').then(() => {

      console.log("this.$store.state: ",this.$store.state);
      
      for (var i in this.$store.state.packageTypes) {
        let packageType = this.$store.state.packageTypes[i];
        this.packageTypes[packageType.code] = packageType;
        packageType.iconImage = `https://cdn.senditworld.com/quote-n-book/img/package-${packageType.code}.svg`;
        if(packageType.code == 'passport'){
          packageType.iconImage = `https://cdn.senditworld.com/quote-n-book/img/package-${packageType.code}-2.svg`;
        }
        this.showPackageList = true;
      }
      this.initTypeDropdown();
    });

    let pkg = this.$store.state.quote.params.packages[0];
    
    /* let pkg = {
      type: this.$store.state.quote.params.type,
      weight: this.$store.state.quote.params.weight,
      value: this.$store.state.quote.params.value,
      length: this.$store.state.quote.params.length,
      width: this.$store.state.quote.params.width,
      height: this.$store.state.quote.params.height,
      description: '',
    }; */
    // this.$store.dispatch('addUnlockedStep', 'bookingPackages');
    this.addNewPackage(pkg);
  },
  methods: {
    initTypeDropdown() {
      this.tsTypeDropdown = new TomSelect(this.$refs.typeDropdownEl, {
        valueField: 'code',
        searchField: 'name',
        options: this.$store.state.packageTypes,
        render: {
          option: function(data, escape) {
            return (
              '<div class="type">' +
              '<div class="icon-wrapper">' +
              '<div class="icon" style="background-image: url(\'' +
              data.iconImage +
              '\')"></div>' +
              '</div>' +
              '<div class="name">' +
              escape(data.name) +
              '</div>' +
              '</div>'
            );
          },
          item: function(data, escape) {
            return (
              '<div class="type">' +
              '<div class="icon-wrapper">' +
              '<div class="icon" style="background-image: url(\'' +
              data.iconImage +
              '\')"></div>' +
              '</div>' +
              '<div class="name">' +
              escape(data.name) +
              '</div>' +
              '</div>'
            );
          },
        },
        items: ['small-box'],
      });
      this.onChange(this.tsTypeDropdown.getValue());
      this.tsTypeDropdown.on('change', this.onChange);
    },
    onPackageChange(event){
       const selectedValue = event.target.value;
      if(selectedValue === "document" || selectedValue === "passport"){
        this.isDisabled = true;
      }else{
        this.isDisabled = false;
      }
    },
    onChange(type) {
      /* let packageType = this.$store.state.packageTypes.find((pt) => {
        return pt.code == type;
      }); */

        let packageType = this.packageTypes[type];
        if (!packageType) {
          return; 
        }
        
        if (packageType.packageImageContent != '') {
            document.querySelector('.package-form .package-shape .shape').innerHTML = packageType.packageImageContent;
            document.querySelector('.package-form .mobile-package-shape .shape').innerHTML = packageType.packageImageContent;
        } else {
            document.querySelector('.package-form .package-shape .shape').style.backgroundImage = "url('" + packageType.packageImage + "')";
            document.querySelector('.package-form .mobile-package-shape .shape').style.backgroundImage = "url('" + packageType.packageImage + "')";
        }

        if(packageType['valueRequired']){
            document.getElementById('value').setAttribute('data-rules', '["required", "numeric"]');
        } else {
            document.getElementById('value').setAttribute('data-rules', '');
        }

        this.fillInForm(packageType);
      
    },
    fillInForm(packageType) {
      //this.pkg.value = packageType.value || 0;
      this.pkg.weight = packageType.weight;
      this.pkg.length = packageType.length;
      this.pkg.width = packageType.width;
      this.pkg.height = packageType.height;

      this.maxWeight = packageType.maxWeight;
    },
    editPackage(index) {
      this.packageForm = 'edit';
      this.formState = null;

      this.resetBlur();

      let editingPackage = this.$store.state.booking.data.packages[index];

      this.editingPackageIndex = index;

      this.tsTypeDropdown.addItem(editingPackage['type']);

      this.pkg.weight = editingPackage['weight'];
      this.pkg.value = editingPackage['value'];
      this.pkg.length = editingPackage['length'];
      this.pkg.width = editingPackage['width'];
      this.pkg.height = editingPackage['height'];
      this.pkg.description = editingPackage['description'];
    },
    newPackage() {
      console.log('adding new')
      this.packageForm = 'new';

      this.tsTypeDropdown.addItem(Object.keys(this.packageTypes)[0]);
      this.fillInForm(this.packageTypes[this.tsTypeDropdown.getValue()]);
    },
    addNewPackage(pkg) {
      console.log("nextStep", this.isNextStep);
      if (this.isNextStep) {
        
      this.packageForm = null;

      this.$store.dispatch('addBookingPackage', pkg);
      window.dispatchEvent(new Event('refreshBookingQuote'));
      this.$store.dispatch('setBookingConfirmation', null);
      this.$store.dispatch('removeUnlockedStep', 'bookingActions');
      this.$store.dispatch('readyForPayment', false);
      this.$store.dispatch('goToNextBookingStep');
      }
      
    },
    updatePackage(pkg) {
      this.packageForm = null;

      this.$store.dispatch('updateBookingPackage', { index: this.editingPackageIndex, pkg: pkg });
      window.dispatchEvent(new Event('refreshBookingQuote'));
      this.$store.dispatch('setBookingConfirmation', null);
      this.$store.dispatch('removeUnlockedStep', 'bookingActions');
      this.$store.dispatch('readyForPayment', false);
      this.$store.dispatch('goToNextBookingStep');
    },
    showDeleteConfirmBox(event, index) {
      const packageElement = event.target.closest('.package');

      if (!packageElement.querySelector('.confirm-box')) {
        let confirmBox = document.createElement('div');
        confirmBox.classList.add('confirm-box');
        confirmBox.innerHTML =
          `<div class='inner-wrapper'><div class='title'>Are you sure?</div>
        <div class='actions'>
        <a class='btn yes' data-index="` +
          index +
          `">Yes</a>
        <a class='btn no'>No</a>
        </div></div>`;
        event.target.closest('.package').appendChild(confirmBox);
        event.target.closest('.package').classList.add('confirm');
      }
    },
    deletePackage(index) {
      this.$store.dispatch('deleteBookingPackage', index);
      window.dispatchEvent(new Event('refreshBookingQuote'));
      this.$store.dispatch('readyForPayment', false);
      this.$store.dispatch('setBookingConfirmation', null);
      this.$store.dispatch('removeUnlockedStep', 'bookingActions');
      this.$store.dispatch('goToNextBookingStep');

      if (this.$store.state.booking.data.packages.length == 0) {
        this.packageForm = 'new';
      }
    },
    handleDeleteConfirmBoxClicks(e) {
      if (e.target && e.target.classList.contains('btn') && e.target.classList.contains('yes')) {
        const index = e.target.getAttribute('data-index');
        this.deletePackage(index);
        e.target.closest('.package').classList.add('hidden');
        e.target.closest('.confirm-box').remove();
      } else if (e.target && e.target.classList.contains('btn') && e.target.classList.contains('no')) {
        e.target.closest('.confirm-box').remove();
      }
    },
    send() {
      this.error = null;
      this.formState = null;

      // Use object.assign so its cloned and referenced is not copied after edit.
      let clonedPackage = Object.assign({}, this.pkg);
      let pkg = {
        type: clonedPackage['type'],
        weight: clonedPackage['weight'],
        value: clonedPackage['value'],
        length: clonedPackage['length'],
        width: clonedPackage['width'],
        height: clonedPackage['height'],
        description: clonedPackage['description'],
      };

      if (this.packageForm == 'new') {
        this.addNewPackage(pkg);
      } else {
        this.updatePackage(pkg);
      }
    },
    highlightShape(side)
    {
        this.shapeHighlight = 'highlight-' + side;
    },
    unhighlightShape()
    {
        this.shapeHighlight = null;
    }
  },
};
</script>

<style>
.booking .packages .title-n-list {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 0%) !important;
  background: rgb(248, 248, 248) !important;
}

.booking .packages .package-list .cols {
  padding: 0px 0px !important;
}

.booking .booking-collection .cols .box {
  min-height: 0px !important;
}

.new-branding-form .form-style-1 button{
  background-color: #157a94;
  background-image: linear-gradient(90deg, #1985a1 0, #024455);
  border-radius: 5px;
  box-shadow: none;
}

.booking .packages .package-form .form-title-wrapper .title:before{
  border: 2px solid #1985a1 !important;
}

#bookingPackages .confirm-box{
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: 500;
  color: #201f31;
  text-decoration: underline;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

#bookingPackages .confirm-box .actions{
  padding-top: 0px;
}

#bookingPackages .confirm-box .inner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>