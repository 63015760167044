<template>
    <div
        id="booking_collectionAddress"
        class="collection-address"
        style="background-color: #fff !important;"
        :class="{ editing: editing }"
    >
        <div class="inner-wrapper">

            <div class="the-form">
                <div class="form-outer-wrapper form-style-1" :class="formState">
                    <div class="form-wrapper">
                        <div class="form">
                            <div class="form-error" v-if="error">
                                <div class="error">{{ error }}</div>
                            </div>

                             
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="stepper-wrapper">
                                        <div :class="['stepper-item', { 'completed': currentStep >= 1 }]" @click="() => { currentStep = 1; if (currentStep === 1) initFunctionForStep1(); }">
                                            <div class="step-counter">1</div>
                                            <div class="step-name">Addresses</div>
                                        </div>
                                        <!-- <div :class="['stepper-item', { 'completed': currentStep >= 2 }]" @click="currentStep = 2">
                                            <div class="step-counter">2</div>
                                            <div class="step-name">Pickup dates & Time</div>
                                        </div> -->
                                        <div :class="['stepper-item', { 'completed': currentStep >= 2 }]" @click="handleStep2()">
                                            <div class="step-counter">2</div>
                                            <div class="step-name">Pickup dates & Time</div>
                                        </div>
                                        <div :class="['stepper-item', { 'completed': currentStep >= 3 }]" @click="handleStep3()">
                                            <div class="step-counter">3</div>
                                            <div class="step-name">Summary</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="price-card" style="border: 1px solid #ECECEC; padding: 15px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; border-radius: 10px;">
                                        <h3 style="font-size: 18px;">Total</h3>
                                        <h1 style="font-size: 30px; color: #187790;"><h2 class="unit hidden">AED</h2>AED {{ finalPrice  }}</h1>
                                    </div>
                                </div>
                            </div>
                                <!-- <div class="col-md-">
                                    <button type="button" class="btn" @click="showAddressForm">Add Address</button>
                                </div> -->
                            <form
                                action=""
                                @focusout="change"
                                @input="change"
                                @submit="submit"
                                @submit.prevent="handleSubmit"
                                novalidate
                            >
                                <div v-if="currentStep === 1">
                                     <div class="row d-flex mb-4 mt-3">
                                        <div class="col-md-4">
                                            <div class="discount" style="display: flex; flex-wrap: wrap; margin-bottom: 3px;">
                                                <img src="../../assets/discount.png" alt="" />
                                                <p style="margin: 1px 5px; color: rgb(24, 119, 144);">Add discount coupon</p>
                                            </div>
                                            <input type="text" class="form-control" v-model="formData.collection.coupon">
                                        </div>
                                        <div class="col-md-2">
                                            <button @click.prevent="applyCoupon" class="btn btn-primary-next mt-4">ADD</button>
                                        </div>
                                <Addadress />


                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6" style="background: #F8F8F8; padding: 15px 11px; margin-left: -10px; border-radius: 10px;">
                                            <h2>Collection Address</h2>
                                            <!-- Form Fields for Collection Address -->
                                            <div class="inner-title-wrapper">
                                                <!-- <h5 class="" style="visibility: hidden;">Collection Address</h5> -->
                                                <div
                                                    class="address-list-dropdown"
                                                    :class="{ open: addressListDropdown.open }"
                                                >
                                                <label>Saved Address</label>
                                                    <a
                                                        href="#"
                                                        class="trigger"
                                                        @click.prevent="toggleAddressListDropdown()"
                                                        > {{ addressListDropdown.value.name }}</a
                                                    >
                                                    <div class="content">
                                                        <div class="inner-content-wrapper">
                                                            
                                                            <a
                                                                href="#"
                                                                class="address"
                                                                @click.prevent="selectAddress(item)"
                                                                v-for="(item, index) in addresses"
                                                                :key="index"
                                                            >
                                                                {{ item.name }}
                                                            </a>
                                                             <!-- <a
                                                                href="#"
                                                                class="address"
                                                                @click.prevent="selectAddress('new-address')"
                                                            >
                                                                New Address
                                                            </a> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                <div class="form-group">
                                                    <label>Collection Address</label>
                                                    <input type="text" name="collection-address" class="form-control" v-model="addressListDropdown.primary" disabled>
                                                </div>
                                                
                                                <div class="form-group">
                                                    <div
                                                        class="input-field-wrapper" style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.name.errorMessage,
                                                        }"
                                                    >
                                                        <label>Full Name</label>
                                                        <div class="input-field">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                data-rules='["required"]'
                                                                v-model="addressListDropdown.name"
                                                                disabled
                                                            />
                                                            <!-- <input type="text" class="form-control" v-model="formData.collection.fullName"> -->
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="validation.name.errorMessage"
                                                    >
                                                        {{ validation.name.errorMessage }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <div
                                                        class="
                                                            input-field-wrapper
                                                            phone-input-field-wrapper
                                                        " style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.contactMobile
                                                                    .errorMessage,
                                                        }"
                                                    >
                                                        <label>Contact Number</label>
                                                        <!-- <input type="text" class="form-control" v-model="addressListDropdown.contactMobile"> -->
                                                        <input type="text" class="form-control" v-model="countryCodePhoneAddressList">
                                                        <!-- <div class="input-field-inner-wrapper">
                                                             <div class="country-code-dropdown">
                                                                <select
                                                                    name="countryCode"
                                                                    placeholder="Search"
                                                                    ref="countryCodeDropdownEl"
                                                                    data-rules='["required"]'
                                                                    v-model="countryCode"
                                                                ></select>
                                                            </div>
                                                            <div class="input-field">
                                                                <input
                                                                    type="text"
                                                                    class="plain-input"
                                                                    name="contactMobile"
                                                                    id="contactMobile"
                                                                    data-rules='["required"]'
                                                                    v-model="addressListDropdown.contactMobile"
                                                                />
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="
                                                            validation.contactMobile
                                                                .errorMessage
                                                        "
                                                    >
                                                        {{
                                                            validation.contactMobile
                                                                .errorMessage
                                                        }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <div
                                                        class="input-field-wrapper"
                                                        style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.contactEmail
                                                                    .errorMessage,
                                                        }"
                                                    >
                                                        <label>Postcode</label>
                                                        <!-- <input type="email" class="form-control" v-model="formData.collection.email"> -->
                                                        <div class="input-field">
                                                            <input
                                                                type="text"
                                                                name="contactEmail"
                                                                id="contactEmail"
                                                                data-rules='["required"]'
                                                                v-model="
                                                                    addressListDropdown.zipCode
                                                                "
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="
                                                            validation.contactEmail.errorMessage
                                                        "
                                                    >
                                                        {{
                                                            validation.contactEmail.errorMessage
                                                        }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <div
                                                        class="input-field-wrapper"
                                                        style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.addressLine1
                                                                    .errorMessage,
                                                        }"
                                                    >
                                                        <label>Address Line 1</label>
                                                        <input type="text" class="form-control" v-model="addressListDropdown.primary" disabled>
                                                        <!-- <div class="input-field">
                                                            <input
                                                                type="text"
                                                                name="addressLine1"
                                                                id="addressLine1"
                                                                data-rules='["required"]'
                                                                v-model="
                                                                formData.collection.addressLine1
                                                                "
                                                                disabled
                                                            />
                                                        </div> -->
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="
                                                            validation.addressLine1.errorMessage
                                                        "
                                                    >
                                                        {{
                                                            validation.addressLine1.errorMessage
                                                        }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <div
                                                        class="input-field-wrapper"
                                                        style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.addressLine2
                                                                    .errorMessage,
                                                        }"
                                                    >
                                                        <label>Address Line 2 (landmark)
                                                            <!-- <span>Optional</span> -->
                                                        </label>
                                                        <div class="input-field">
                                                            <input
                                                                type="text"
                                                                name="addressLine2"
                                                                id="addressLine2"
                                                                v-model="
                                                                    formData.collection.addressLine2
                                                                "
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="
                                                            validation.addressLine2.errorMessage
                                                        "
                                                    >
                                                        {{
                                                            validation.addressLine2.errorMessage
                                                        }}
                                                    </p>
                                                </div>

                                                <div class="form-group">
                                                    <div
                                                        class="input-field-wrapper"
                                                        style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.city.errorMessage,
                                                        }"
                                                    >
                                                        <label>Town/City</label>
                                                        <!-- <input type="text" class="form-control" v-model="formData.collection.townCity"> -->
                                                        <div class="input-field">
                                                            <input
                                                                type="text"
                                                                name="city"
                                                                id="city"
                                                                data-rules='["required"]'
                                                                v-model="addressListDropdown.cityName"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="validation.city.errorMessage"
                                                    >
                                                        {{ validation.city.errorMessage }}
                                                    </p>
                                                </div>
                                                

                                                <div class="form-group">
                                                    <label>Country</label>
                                                     <input
                                                                type="text"
                                                                name="city"
                                                                id="city"
                                                                data-rules='["required"]'
                                                                v-model="addressListDropdown.countryName"
                                                                disabled
                                                            />
                                                    <!-- <div class="input-field">
                                                        <select
                                                            name="country"
                                                            id="country"
                                                            placeholder="Search"
                                                            ref="countryDropdownEl"
                                                            data-rules='["required"]'
                                                            v-model="
                                                                addressListDropdown.countryName
                                                            "
                                                        ></select>
                                                    </div> -->
                                                </div>
                                                <input type="hidden" name="city" v-model="addressListDropdown.countryCode" />
                                            <!-- </form> -->
                                        </div>
                    
                                        <div class="col-md-6" style="background: #F8F8F8; padding: 15px 11px; margin-left: 10px; border-radius: 10px;">
                                            <h2>Delivery Address</h2>
                                            <DestinationAddress v-model="destinationAddress" />
                                                <!-- Form Fields for Delivery Address -->
                                            <div class="form-group" style="margin-top: 14px;">
                                                <label>Destination Address</label>
                                                <input type="text" name="destination-address" class="form-control" v-model="destinationAddress.primary" disabled>
                                            </div>
                                            <!-- <div class="form-group">
                                                <label>Destination Address (Secondary)</label>
                                                <input type="text" name="destination-address-secondary" class="form-control" v-model="destinationAddress.secondary">
                                              </div> -->
                                            <div class="form-group">
                                                <label>Full Name</label>
                                                <input type="text" class="form-control" v-model="destinationAddress.name" disabled>
                                            </div>
                                             <div class="form-group">
                                                    <div
                                                        class="
                                                            input-field-wrapper
                                                            phone-input-field-wrapper
                                                        " style="display: inline;"
                                                        :class="{
                                                            invalid:
                                                                validation.contactMobile
                                                                    .errorMessage,
                                                        }"
                                                    >
                                                        <label>Contact Number test 1</label>
                                                        <input type="text" class="form-control" v-model="countryCodePhoneDestination">
                                                        <!-- <div class="input-field-inner-wrapper">
                                                             <div class="country-code-dropdown">
                                                                <select
                                                                    name="countryCode"
                                                                    placeholder="Search"
                                                                    ref="countryCodeDropdownE2"
                                                                    data-rules='["required"]'
                                                                    v-model="destinationAddress.contactMobile"
                                                                ></select>
                                                            </div>
                                                            <div class="input-field">
                                                                <input
                                                                    type="text"
                                                                    class="plain-input"
                                                                    name="contactMobile"
                                                                    id="contactMobile"
                                                                    data-rules='["required"]'
                                                                    v-model="destinationAddress.contactMobile"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <p
                                                        class="error-message"
                                                        v-if="
                                                            validation.contactMobile
                                                                .errorMessage
                                                        "
                                                    >
                                                        {{
                                                            validation.contactMobile
                                                                .errorMessage
                                                        }}
                                                    </p>
                                                </div>
                                            <div class="form-group">
                                                <div
                                                    class="input-field-wrapper"
                                                    style="display: inline;"
                                                    :class="{
                                                        invalid:
                                                            validation.zipCode.errorMessage,
                                                    }"
                                                >
                                                    <label>Postcode</label>
                                                    <!-- <input type="text" class="form-control" v-model="formData.delivery.postcode"> -->
                                                    <div class="input-field">
                                                        <input
                                                                type="text"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                data-rules=""
                                                                v-model="
                                                                    destinationAddress.zipCode
                                                                "
                                                                disabled
                                                            />
                                                        <!-- <div class="half" style="display: flex;">
                                                            <input
                                                                type="text"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                data-rules=""
                                                                v-model="
                                                                    addressListDropdown.zipCode
                                                                "
                                                            />
                                                            <button type="button" class="btn btn-booking-bn mt-2">Search</button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <p
                                                    class="error-message"
                                                    v-if="validation.zipCode.errorMessage"
                                                >
                                                    {{ validation.zipCode.errorMessage }}
                                                </p>
                                            </div>
                                            <div class="form-group">
                                                <label>Address Line 1
                                                    <!-- <span>Optional</span> -->
                                                </label>
                                                <div
                                                    class="input-field-wrapper"
                                                    :class="{
                                                        invalid:
                                                            validation.landmark
                                                                .errorMessage,
                                                    }"
                                                >
                                                    <!-- <input type="text" class="form-control" v-model="formData.delivery.addressLine1"> -->
                                                    <div class="input-field">
                                                        <input
                                                            type="text"
                                                            name="landmark"
                                                            id="landmark"
                                                            v-model="
                                                                destinationAddress.primary
                                                            "
                                                             required
                                                             disabled
                                                    />
                                                    </div>
                                                </div>
                                                <p
                                                    class="error-message"
                                                    v-if="validation.landmark.errorMessage"
                                                >
                                                    {{ validation.landmark.errorMessage }}
                                                </p>
                                            </div>
                                            <div class="form-group">
                                            <label>Address Line 2 (landmark)</label>
                                            <input type="text" class="form-control" v-model="destinationAddress.addressLine1" disabled>
                                            </div>
                                            <div class="form-group">
                                            <label>Town/City</label>
                                            <input type="text" class="form-control" v-model="destinationAddress.cityName" disabled>
                                            </div>
                                            <div class="form-group">
                                            <label>Country</label>
                                            <!-- <div class="input-field"> -->
                                                 <input type="text" class="form-control" v-model="destinationAddress.countryName" disabled>
                                                        <!-- <select
                                                            name="country"
                                                            id="country"
                                                            placeholder="Search"
                                                            ref="countryDropdownE2"
                                                            data-rules='["required"]'
                                                            v-model="
                                                                addressListDropdown.countryName
                                                            "
                                                            disabled
                                                        ></select> -->
                                                    <!-- </div> -->
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-4">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6">
                                            <div class="d-flex justify-content-end mt-4">
                                            <button class="btn btn-primary-next" @click="nextStep">Next</button>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                                <div v-if="currentStep === 2">
                                    <!-- Pickup Dates & Time -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div
                                                    class="input-field-wrapper" style="display: inline;"
                                                    :class="{
                                                        invalid:
                                                            validation.name.errorMessage,
                                                    }"
                                                >
                                                    <label for="dob">Date</label>
                                                    <div class="input-field custom-date-icon">
                                                        <!-- <input name="date" type="date" id="dob" class="form-control " v-model="formData.collection.date" data-rules='["required"]'> -->
                                                        <flat-pickr 
                                                            v-model="formData.collection.date"
                                                            :config="flatpickrConfig"
                                                            class="form-control"
                                                            id="dob"
                                                            placeholder="Select a date"
                                                            />
                                                            <svg width="22px" height="22px" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M128 384v512h768V192H768v32a32 32 0 11-64 0v-32H320v32a32 32 0 01-64 0v-32H128v128h768v64H128zm192-256h384V96a32 32 0 1164 0v32h160a32 32 0 0132 32v768a32 32 0 01-32 32H96a32 32 0 01-32-32V160a32 32 0 0132-32h160V96a32 32 0 0164 0v32zm-32 384h64a32 32 0 010 64h-64a32 32 0 010-64zm0 192h64a32 32 0 110 64h-64a32 32 0 110-64zm192-192h64a32 32 0 010 64h-64a32 32 0 010-64zm0 192h64a32 32 0 110 64h-64a32 32 0 110-64zm192-192h64a32 32 0 110 64h-64a32 32 0 110-64zm0 192h64a32 32 0 110 64h-64a32 32 0 110-64z"/></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="time mt-3" style="background: #F8F8F8; padding: 12px; border-radius: 10px;">
                                                <h1 style="padding-left: 10px; font-size: 23px;">Select time</h1>
                                                <div class="inner-time" style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                                                    <div class="input-container" style="position: relative; margin-right: 10px;">
                                                        <input
                                                          type="radio"
                                                          id="morning"
                                                          value="morning"
                                                          v-model="selectedTime"
                                                          @change="selectDefaultSlot"
                                                        />
                                                        <label
                                                          for="morning"
                                                          style="width:100%; height:100%; position:absolute; border: 2px solid #DDDDDD; top:0; left:0; 
                                                               font-family:arial; color: #000; background-color: #DDDDDD; display: flex; align-items: center; justify-content: center;"
                                                        >
                                                          Morning
                                                        </label>
                                                    </div>
                                                    <div class="input-container" style="position: relative;">
                                                        <input
                                                          type="radio"
                                                          id="afternoon"
                                                          value="afternoon"
                                                          v-model="selectedTime"
                                                          @change="selectDefaultSlot"
                                                        />
                                                        <label
                                                          for="afternoon"
                                                          style="width:100%; height:100%; position:absolute; border: 2px solid #DDDDDD; top:0; left:0; 
                                                               font-family:arial; color: #000; background-color: #DDDDDD; display: flex; align-items: center; justify-content: center;"
                                                        >
                                                          Afternoon
                                                        </label>
                                                    </div>
                                                </div>
                                              
                                                <!-- Time Slots for Morning -->
                                                <div v-if="selectedTime === 'morning'" class="time-slots-container morning-slots">
                                                    <div class="slot-option morning-slot">
                                                        <input
                                                            type="radio"
                                                            id="morning-slot1"
                                                            value="10 to 12"
                                                            v-model="selectedSlot"
                                                        />
                                                        <label for="morning-slot1" style="flex: 0 0 auto;">10am to 12pm</label>
                                                    </div>
                                                    <!-- <div class="slot-option morning-slot">
                                                        <input
                                                            type="radio"
                                                            id="morning-slot2"
                                                            value="8 to 10"
                                                            v-model="selectedSlot"
                                                        />
                                                        <label for="morning-slot2" style="flex: 0 0 auto;">8 to 10</label>
                                                    </div> -->
                                                </div>

                                                <!-- Time Slots for Afternoon -->
                                                <div v-if="selectedTime === 'afternoon'" class="time-slots-container afternoon-slots">
                                                    <div class="slot-option afternoon-slot">
                                                        <input
                                                            type="radio"
                                                            id="afternoon-slot3"
                                                            value="1 to 5"
                                                            v-model="selectedSlot"
                                                        />
                                                        <label for="afternoon-slot3" style="flex: 0 0 auto;">1pm to 5pm</label>
                                                    </div>
                                                </div>
                                                <div class="discount mt-2" style="display: flex; flex-wrap: wrap; margin-bottom: 3px; margin-left: 10px;">
                                                    <img src="../../assets/i.png" alt="" />
                                                    <p style="margin: 1px 2px; font-size: 14px; color: rgb(24, 119, 144);">collection may get slightly delayed due to traffic</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Form for Step 2 -->
                                    <div class="d-flex justify-content-between mt-4">
                                        <button class="btn btn-primary-back" @click="prevStep">Back</button>
                                        <button class="btn btn-primary-next" @click="nextStep">Next</button>
                                    </div>
                                </div>
                                <div v-if="currentStep === 3">
                                    <!-- Summary -->
                                    <h2 class="mt-4 mb-4" style="font-size: 24px; font-weight: 600;">Order Summary</h2>
                                
                                    <!-- Order Item Details -->
                                    <div class="summary-order" style="background: #F8F8F8; padding: 15px 22px; border-radius: 15px;">
                                        <div class="order-item mt-4">
                                            <h2 class="mb-3" style="font-size: 15px; font-weight: 500;">Order Item</h2>
                                            <Packages :addressListDropdown="addressListDropdown" :destinationAddress="destinationAddress" @triggerAddPackage="addNewPackage" :pkg="pkg" :isNextStep="isNextStep"/>
                                     
                                            <!-- <div>
                                                <img src="../../assets/dhl.png" alt="DHL" class="img-fluid mt-4" style="width: 100px;" />
                                                <h2 class="mt-2 mb-2" style="font-size: 18px;"><strong>Economy</strong></h2>
                                                <p class="mt-2 mb-2">Insured</p>
                                                <p class="mt-2 mb-2"><strong>Delivery Time:</strong> 2-4 business days</p>
                                            </div> -->
                                            <DeliveryService/>
                                        </div>
                                
                                        <!-- Addresses Section -->
                                        <div class="address-section mt-4">
                                            <!-- Collection Address -->
                                            <div class="row p-3">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <p><strong>Collection Details</strong></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Full Name: </strong> <span class="ml-3">{{ addressListDropdown.name }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Contact Number: </strong> <span class="ml-3">{{ addressListDropdown.contactMobile }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Email: </strong> <span class="ml-3">{{ addressListDropdown.contactEmail }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Address line 1: </strong> <span class="ml-3">{{ addressListDropdown.primary }}</span></p>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <p><strong>Address Line 1: </strong> <span class="ml-3">{{ addressListDropdown.addressLine1 }}</span></p>
                                                    </div> -->
                                                    <div class="row">
                                                        <p><strong>Address Line 2: </strong> <span class="ml-3">{{ addressListDropdown.addressLine2 }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Town/City: </strong> <span class="ml-3">{{ addressListDropdown.cityName }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Country: </strong> <span class="ml-3">{{ addressListDropdown.countryName }}</span></p>
                                                    </div>
                                                     <div class="row">
                                                        <p><strong>Pickup Date: </strong> <span class="ml-3">{{ formData.collection.date }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Pickup Time: </strong> <span class="ml-3">{{ formatTimeSlot(selectedSlot, selectedTime) }}</span></p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <p><strong>Delivery Details</strong></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Full Name: </strong> <span class="ml-3">{{ destinationAddress.name }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Contact Number: </strong> <span class="ml-3">{{ destinationAddress.contactMobile }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Email: </strong> <span class="ml-3">{{ destinationAddress.contactEmail }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Address line 1: </strong> <span class="ml-3">{{ destinationAddress.primary }}</span></p>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <p><strong>Address Line 1: </strong> <span class="ml-3">{{ addressListDropdown.addressLine1 }}</span></p>
                                                    </div> -->
                                                    <div class="row">
                                                        <p><strong>Address Line 2: </strong> <span class="ml-3">{{ destinationAddress.addressLine2 }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Town/City: </strong> <span class="ml-3">{{ destinationAddress.cityName }}</span></p>
                                                    </div>
                                                    <div class="row">
                                                        <p><strong>Country: </strong> <span class="ml-3">{{ destinationAddress.countryName }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        <!-- Discount Code and Total -->
                                        <div class="discount-total mt-4">
                                            <p><strong>Discount code <br></strong> {{ formData.collection.coupon }}</p>
                                            <h3 style="font-size: 20px;">Total <br><span style="color: rgb(24, 119, 144); font-size: 35px; font-weight: 500;">{{ finalPrice  }}</span >                                            
                                            <span v-if="discountAmount" style="text-decoration: line-through; margin-left: 6px; font-size: 24px;">
                                                 {{ deliveryServicePrice }}
                                            </span></h3>
                                        </div>
                                    </div>
                                
                                    <!-- Confirmation Section -->
                                    <div class="confirmation mt-4">
                                        <!-- Confirmation Header -->
                                        <div class="discount mt-2 d-flex align-items-center mb-2" style="margin-left: 10px;">
                                            <img src="../../assets/mingcute.png" alt="Confirmation Icon" style="width: 24px; height: 24px; margin-right: 5px;">
                                            <p style="margin: 0; font-size: 16px; font-weight: 600; color: rgb(24, 119, 144);">Confirmation</p>
                                        </div>
                                        
                                        <!-- Agreement Checkboxes -->
                                        <div class="agree" style="margin-left: 10px;">
                                            <div class="form-check mb-4">
                                                <input type="checkbox" class="form-check-input custom-checkbox" id="confirmPrint" v-model="confirmPrint">
                                                <label class="form-check-label" for="confirmPrint" style="margin-left: 10px; background: transparent; color: #000 !important; border: none !important;">
                                                    I agree that I have access to a printer to print out the waybill, invoice, and packing list needed for when my package is collected.
                                                </label>
                                            </div>
                                            
                                            <div class="form-check mb-4">
                                                <input type="checkbox" class="form-check-input custom-checkbox" id="confirmItems" v-model="confirmItems">
                                                <label class="form-check-label" for="confirmItems" style="margin-left: 10px; background: transparent; color: #000 !important; border: none !important;">
                                                   I have read and agree to the Send it Terms and Conditions. I agree that none of the items included in my shipment are on the <a href="https://staging.senditworld.com/prohibited-items/" target="_blank" style="color: blue; cursor: pointer;"> PROHIBITED ITEMS </a> list.
                                                </label>
                                                <ul class="mt-2" style="padding-left: 20px; list-style-type: disc;">
                                                    <!-- <li>Medical facemasks</li>
                                                    <li>Batteries, Li-ion batteries, and loose batteries</li>
                                                    <li>No refund option available after payment is made.</li>
                                                    <li>Liquids: Any type of liquids - Shampoo, perfume, alcohol, gels, and/or printer cartridges.</li> -->
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <!-- Navigation Buttons -->
                                    <div class="d-flex justify-content-unset mt-4">
                                        <!-- <button class="btn btn-primary-back" @click="prevStep">Back</button> -->
                                        <div id="bookingActions" class="booking-form-section booking-actions" :class="{ unlocked: $store.state.booking.unlockedSteps.includes('bookingActions') }">
                                            <div class="content" :class="{'payment': $store.state.booking.readyForPayment}">
                                              
                                              <div class="booking-error" v-if="error">
                                                <div class="error">{{ error }}</div>
                                              </div>
                                        
                                              <div id="booking_actions" class="box actions">
                                                <a href="#" class="btn btn-primary-next" @click.prevent="book()">Submit</a>
                                              </div>
                                        
                                              <div class="box payments collection-address">
                                                <a :href="paymentURL" class="btn btn-primary-next pay">Pay AED {{ finalPrice }}</a>
                                                <!-- <a :href="paymentURL" class="btn btn-primary-next pay">Pay AED {{ deliveryServicePrice }}</a>  -->
                                                <div class="note">
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        <!-- <button type="submit" class="btn btn-primary-next" @click="showModal('submitForm')">Submit</button> -->
                                        <!-- <button type="submit" class="">Save</button> -->
                                        <!-- <button style="margin-top: 19px;" class="btn btn-primary-other-order ml-2" @click="showModal('submitAndAddAnother')">Submit & Add Another order</button> -->
                                        <router-link to="/get-a-quote" class="btn btn-primary-other-order ml-2">Submit & Add Another order</router-link>
                                    </div>
                                </div>
                            </form>
                                <!-- <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.name.errorMessage,
                                        }"
                                    >
                                        <label for="name"
                                            >Name
                                            <span>e.g Home or Office</span>
                                        </label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                data-rules='["required"]'
                                                v-model="collectionAddress.name"
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.name.errorMessage"
                                    >
                                        {{ validation.name.errorMessage }}
                                    </p>
                                </div> -->

                                <div class="form-group hidden">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.country.errorMessage,
                                        }"
                                    >
                                        <label for="country">Country</label>
                                        <!-- <div class="input-field">
                                            <select
                                                name="country"
                                                id="country"
                                                placeholder="Search"
                                                ref="countryDropdownEl"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.country
                                                "
                                            ></select>
                                        </div> -->
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.country.errorMessage"
                                    >
                                        {{ validation.country.errorMessage }}
                                    </p>
                                </div>

                                <div
                                    class="form-group hidden"
                                    v-show="collectionAddress.country == 'AE'"
                                >
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.state.errorMessage,
                                        }"
                                    >
                                        <label for="state">State</label>
                                        <div class="input-field">
                                            <select
                                                name="state"
                                                id="state"
                                                placeholder="State"
                                                ref="stateDropdownEl"
                                                v-model="
                                                    collectionAddress.state
                                                "
                                            >
                                                <option
                                                    :value="state.code"
                                                    v-for="state in $store.state
                                                        .uaeStates"
                                                    :key="state.code"
                                                >
                                                    {{ state.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.state.errorMessage"
                                    >
                                        {{ validation.state.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-group hidden">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.addressType
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="addressType"
                                            >Type of Address</label
                                        >
                                        <div class="input-field">
                                            <select
                                                id="addressType"
                                                name="addressType"
                                                placeholder="Address Type"
                                                ref="addressTypeDropdownEl"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.addressType
                                                "
                                            >
                                                <option value="Home">
                                                    Home
                                                </option>
                                                <option value="Office">
                                                    Office
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.addressType.errorMessage
                                        "
                                    >
                                        {{
                                            validation.addressType.errorMessage
                                        }}
                                    </p>
                                </div>
                        </div>
                    </div>
                    <div class="loading-overlay">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div v-if="isModalVisible" class="modal-overlay">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click="closeModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="logo-wrapper" style="text-align: center;">
                            <a :href="marketingSiteUrl" class="logo">
                              <img class="" src="../../assets/header-logo.png" alt="" />
                            </a>
                            <a href="#" class="hamburger" @click.prevent="toggleMenu()"></a>
                            <div class="model-text">
                                <p style="font-size: 27px; font-weight: bold; color: rgb(24, 119, 144);">Thankyou for placing your order</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="display: block; text-align: center !important;">
                        <!-- <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button> -->
                        <button style="color: rgb(255, 255, 255); line-height: 9px; background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); padding: 22px !important; border-radius: 10px;" type="button" class="btn btn-primary" @click="handleModalAction">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import * as TomSelect from "tom-select";
//import { mapGetters } from 'vuex';
import DestinationAddress from '@/components/booking/DestinationAddress.vue';
import Packages from '@/components/booking/Packages.vue';
import DeliveryService from '@/components/booking/DeliveryService.vue';
import formValidation from "@/mixins/formValidation";
import Addadress from "@/components/Addadress.vue"

// Flat Picker 

import 'flatpickr/dist/flatpickr.css';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: "CollectionAddress",
    components: {
        Packages,
        DeliveryService,
        DestinationAddress,
        Addadress,
        FlatPickr
    },
    mixins: [formValidation],
    data() {
        return {
            selectedTime: 'afternoon',
           selectedSlot: '1 to 5',
           isFormVisible: false,
           showaddress: false,
           addressFormm: '',
           isNextStep: false,
            // total: 0,
            paymentURL: '', // Initialize paymentURL
            addresses: [],
            showNewAddressForm: false,
            isModalVisible: false,
            // showForm: false,
            actionType: null,
            currentStep: 1,
            discountAmount: 0, // To store the discount amount
            errorMessage: '', // To display error messages
            discountApplied: false, // To track if discount is applied
            totalAmount: 100,
            addressListDropdown: {
                open: false,
                name: '',
                contactName: '',
                contactEmail: '',
                contactMobile: '',
                cityName: '',
                countryCode: '',
                countryCode2: '',
                countryDialCode: '',
                // countryName: '',
                secondarycountryName: '',
                landmark: '',
                zipCode: '',
                primary: '',
                secondary: '',
                value: {
                    id: '',
                    name: '',
                    contactName: '',
                    contactEmail: '',
                    contactMobile: '',
                    cityName: '',
                    countryCode: '',
                    countryDialCode: '',
                    countryName: '',
                    landmark: '',
                    zipCode: '',
                    primary: '',
                    secondary: '',
                }
            },
            formData: {
                collection: {
                    fullName: "",
                    contactNumber: "",
                    email: "",
                    addressLine1: "",
                    addressLine2: "",
                    townCity: "",
                    country: "AE",
                    coupon : "",
                    date: ""
                },
                delivery: {
                    fullName: "",
                    contactNumber: "",
                    postcode: "",
                    addressLine1: "",
                    addressLine2: "",
                    townCity: "",
                    country: "AE",
                    coupon : "",
                    date: ""
                }
            },
            editing: false,
            validation: {
                inputElements: [],
                name: {},
                contactName: {},
                contactMobile: {},
                contactEmail: {},
                countryCode: {},
                country: {},
                state: {},
                addressType: {},
                addressLine1: {},
                addressLine2: {},
                landmark: {},
                zipCode: {},
                city: {},
            },
            collectionAddress: {
                id: "",
                name: "",
                contactName: "",
                contactMobile: "",
                contactDialCode: "+971",
                contactEmail: "",
                country: "AE",
                state: "DXB",
                addressType: "",
                addressLine1: "",
                addressLine2: "",
                landmark: "",
                zipCode: "",
                city: "",
                coupon : "",
                date: "",
            },
            destinationAddress: {
                name: '',
                id: null,
                primary: '',
                secondary: '',
                contactName: "",
                contactMobile: "",
                contactEmail: "",
                addressLine1: "",
                // addressLine2: "",
                contactDialCode: "+971",
                country: "AE",
                city: "",
        },
        flatpickrConfig: {
            minDate: new Date(),
            disable: [
                function (date) {
                    return date.getDay() === 0 || date.getDay() === 6; 
                }
            ],
            dateFormat: 'Y-m-d',
        },
        };
    },
    mounted() {
        this.initValidation();
        this.initAddressTypeDropdown();
        // this.initializeData();
        this.$store.dispatch("fetchCountries").then(() => {
            this.initCountryDropdown();
            this.tsCountryDropdown.addItem(this.collectionAddress.country);
            // this.tsCountryDropdown.disable();
        });

        this.$store.dispatch("fetchUAEStates").then(() => {
            this.initStateDropdown();       
        });

        // this.$store.dispatch("fetchAddresses").then(() => {
        //     if (this.$store.state.quote.params.type == "domestic") {
        //         this.collectionAddress.country = "AE";
        //         this.collectionAddress.state =
        //             this.$store.state.quote.params.from;
        //     } else {
        //         this.collectionAddress.country =
        //             this.$store.state.quote.params.from;
        //     }

        //     this.filterAddresses(this.$store.state.quote.params.type);

        //     if (this.addresses.length == 0) {
        //         this.selectAddress("new-address");
        //     } else {
        //         this.selectAddress(this.addresses[0]);
        //         //this.$store.dispatch('addUnlockedStep', 'bookingCollection');
        //         //this.$store.dispatch('goToNextBookingStep');
        //     }

        //     if (typeof this.tsCountryDropdown != "undefined") {
        //         this.tsCountryDropdown.addItem(this.collectionAddress.country);
        //         this.tsCountryDropdown.disable();
        //     }

        //     this.tsStateDropdown.addItem(this.collectionAddress.state);
        //     if (this.$store.state.quote.params.sType == "domestic") {
        //         this.tsStateDropdown.disable();
        //     }
        // });
        this.$store.dispatch("fetchAddresses").then(() => {
            this.filterAddresses(this.$store.state.quote.params.type);

            // Ensure all addresses are shown, not just the filtered ones
            if (this.addresses.length == 0) {
                this.selectAddress("new-address");
            } else {
                this.selectAddress(this.addresses[0]);
            }
        });
    },
    watch: {
        'addressListDropdown.value': {
            handler(newValue) {
                // Use a conditional to prevent unnecessary updates
                if (newValue && (this.addressListDropdown.contactName !== newValue.contactName ||
                                this.addressListDropdown.name !== newValue.name || 
                                this.addressListDropdown.contactEmail !== newValue.contactEmail || 
                                this.addressListDropdown.contactMobile !== newValue.contactMobile || 
                                this.addressListDropdown.cityName !== newValue.cityName || 
                                this.addressListDropdown.countryCode !== newValue.countryCode || 
                                this.addressListDropdown.countryDialCode !== newValue.countryDialCode || 
                                this.addressListDropdown.countryName !== newValue.countryName || 
                                this.addressListDropdown.landmark !== newValue.landmark || 
                                this.addressListDropdown.primary !== newValue.primary || 
                                this.addressListDropdown.secondary !== newValue.secondary || 
                                this.addressListDropdown.zipCode !== newValue.zipCode)) {
                    // Update properties only if they have changed
                    this.addressListDropdown.name = newValue.name || '';
                    this.addressListDropdown.contactName = newValue.contactName || '';
                    this.addressListDropdown.contactEmail = newValue.contactEmail || '';
                    this.addressListDropdown.contactMobile = newValue.contactMobile || '';
                    this.addressListDropdown.cityName = newValue.cityName || '';
                    this.addressListDropdown.countryCode = newValue.countryCode || '';
                    this.addressListDropdown.countryDialCode = newValue.countryDialCode || '';
                    this.addressListDropdown.countryName = newValue.countryName || '';
                    this.addressListDropdown.landmark = newValue.landmark || '';
                    this.addressListDropdown.primary = newValue.primary || '';
                    this.addressListDropdown.secondary = newValue.secondary || '';
                    this.addressListDropdown.zipCode = newValue.zipCode || '';
                }
            },
            deep: true
        }
    },
    computed: {
         countryCodePhoneAddressList: {
            get() {
                return `${this.addressListDropdown.countryDialCode} ${this.addressListDropdown.contactMobile}`;
                },
        },
         countryCodePhoneDestination: {
             get() {
                return `${this.destinationAddress.countryDialCode} ${this.destinationAddress.contactMobile}`;
            },
        },
        // Accessing delivery service price from Vuex store
        deliveryServicePrice() {
        return this.$store.state.quote.deliveryService.price || 0;
    },
    // Accessing coupon from formData.collection
    collectionCoupon() {
        return this.$store.state.booking.data.formData.collection.coupon;
    },
    // Compute the final price after applying discount
    finalPrice() {
        const deliveryPrice = Number(this.deliveryServicePrice);
        const discountPercentage = this.discountApplied ? Number(this.discountAmount) : 0;
        const discountValue = (deliveryPrice * discountPercentage) / 100;
        const finalAmount = Math.max(deliveryPrice - discountValue, 0);
        return finalAmount.toFixed(2);
    }

    },
    methods: {

        
        initCountryDropdown () {
            const countries = JSON.parse(
                JSON.stringify(this.$store.state.countries)
            );
            const flagsBaseUrl = this.$store.state.flagsBaseUrl;

            const defaultValue = "AE";
            this.countryCode = defaultValue;

            new TomSelect(this.$refs.countryCodeDropdownEl, {
                valueField: "code",
                searchField: ["name", "dialCode"],
                options: countries,
                maxOptions: 300,
                render: {
                    option: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                    item: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                },
                items: defaultValue,
            });

             new TomSelect(this.$refs.countryCodeDropdownE2, {
                valueField: "code",
                searchField: ["name", "dialCode"],
                options: countries,
                maxOptions: 300,
                render: {
                    option: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                    item: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                },
                items: defaultValue,
            });

            this.tsCountryDropdown = new TomSelect(
                this.$refs.countryDropdownEl,
                {
                    valueField: "code",
                    searchField: "name",
                    options: countries,
                    maxOptions: 300,
                    render: {
                        option: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                        item: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                    },
                    items: "AE",
                }
            );
            this.tsCountryDropdown = new TomSelect(
                this.$refs.countryDropdownE2,
                {
                    valueField: "code",
                    searchField: "name",
                    options: countries,
                    maxOptions: 300,
                    render: {
                        option: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                        item: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                    },
                    items: "AE",
                }
            );
        },
        s(newData) {
            this.addressListDropdown.value = newData;
            console.log("Updated addressListDropdown:", this.addressListDropdown);
        },
        updateAddressData(newData) {
            this.addressListDropdown.value = newData;
            console.log("Updated addressListDropdown with new data:", this.addressListDropdown);
        },

         addNewPackage(pkg) {
      // Your existing addNewPackage logic here
      console.log('addNewPackage called with package:', pkg);
    },


         initFunctionForStep1() {
            console.log("trigger");
            this.$nextTick(() => {
                // if (this.currentStep == 1) { 
                    this.initCountryDropdown();
                // }
            });
            },

            toggleAddressForm() {
            // this.showForm = true
            this.isFormVisible = !this.isFormVisible;
            },
        initAddressTypeDropdown() {
            //{items: this.$refs.addressTypeDropdownEl.value}
            this.tsAddressTypeDropdown = new TomSelect(
                this.$refs.addressTypeDropdownEl
            );

            //this.$refs.addressTypeDropdownEl.closest('.form-group').classList.remove('hidden');
        },
        initStateDropdown() {
            this.tsStateDropdown = new TomSelect(this.$refs.stateDropdownEl);
        },

        handleSubmit() {
            console.log("check from submtie note ", this.formData);
        },

        selectDefaultSlot() {
            if (this.selectedTime === 'morning') {
                this.selectedSlot = '10 to 12';
            } else if (this.selectedTime === 'afternoon') {
                this.selectedSlot = '1 to 5';
            }
        },

        formatTimeSlot(slot, timeOfDay) {
      // Split the slot into start and end times
      const [startTime, endTime] = slot.split(' to ');

      // Determine the correct suffix (AM/PM) based on selectedTime
      let timeSuffix = timeOfDay === 'morning' ? 'am' : 'pm';

      // Format the slot as "1pm to 5pm" or "10am to 12pm"
      return `${startTime}${timeSuffix} to ${endTime}${timeSuffix}`;
    },
        toggleAddressListDropdown(open) {
            open =
                typeof open != "undefined"
                    ? open
                    : !this.addressListDropdown.open;
            this.addressListDropdown.open = open;
        },
         async applyCoupon() {
        this.errorMessage = '';
        const couponCode = this.formData.collection.coupon;

        try {
            const response = await this.validateCoupon(couponCode);

            if (!response.error) {
                this.discountAmount = response.data.coupon.discount;
                this.discountApplied = true;
            } else {
                this.errorMessage = 'Invalid coupon code. Please try again.';
            }
        } catch (error) {
            console.error(error);
            this.errorMessage = 'An error occurred while applying the coupon. Please try again.';
        }
    },

    async validateCoupon(couponCode) {
        const jwtData = JSON.parse(localStorage.getItem('jwt'));
        const token = jwtData.token; 
        console.log("token", token); 
        const response = await fetch('https://admin.senditworld.com/api/v1/coupon/get', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ couponCode }) 
        });
        
        let res = await response.json();

        if(!res.data){
            alert(res.error.message)
        }else{
            alert("Coupon Applied!");
        }

        return res;
    },
        // filterAddresses(type) {
        //     if (type == "domestic") {
        //         this.addresses = this.$store.getters.stateAddresses(
        //             this.collectionAddress.state
        //         );
        //     } else {
        //         this.addresses = this.$store.getters.countryAddresses(
        //             this.collectionAddress.country
        //         );
        //     }
        // },
        filterAddresses() {
            this.addresses = this.$store.state.addresses;
        },
        selectAddress(address) {
            if (address == "new-address") {
                console.log("this.addressListDropdown.value", this.addressListDropdown)
                this.addressListDropdown.value = { name: "New Address" };
                this.$emit("showNewAddressForm", true);
                this.addressFormm = 'new';
                this.editing = true;
                this.showaddress = true;
            } else {
                this.addressListDropdown.value = address;
                this.$emit("showNewAddressForm", false);
                this.editing = false;

                this.$store.dispatch("setBookingCollectionAddress", address.id);
                this.$store.dispatch("goToNextBookingStep");
            }
            this.toggleAddressListDropdown(false);
            this.isFormVisible = !this.isFormVisible;
        },
        // selectAddress(address) {
        //     if (address === "new-address") {
        //         console.log("in the second form", this.addressListDropdown)
        //         this.addressListDropdown.value = { primary: "New Primary", secondary: "New Secondary", name: "New Address" };
        //         this.$emit("showNewAddressForm", true);
        //         this.editing = true;
        //     } else {
        //         this.addressListDropdown.value = { primary: address.primary, secondary: address.secondary, name: address.name };
        //         this.addressListDropdown.value = address;
        //         this.$emit("showNewAddressForm", false);
        //         this.editing = false;

        //         this.$store.dispatch(
        //             "setBookingDestinationAddress",
        //             address.id
        //         );
        //         this.$store.dispatch("goToNextBookingStep");
        //     }
        //     this.$emit("update:modelValue", this.addressListDropdown.value);
        //     this.toggleAddressListDropdown(false);
        // },
        send() {
            this.error = null;

            // Use object.assign so its cloned and referenced is not copied after edit.
            let clonedAddress = Object.assign({}, this.collectionAddress);
            let address = {
                name: clonedAddress.name,
                contactName: clonedAddress.contactName,
                contactMobile: clonedAddress.contactMobile,
                contactDialCode: clonedAddress.contactDialCode,
                contactEmail: clonedAddress.contactEmail,
                type: clonedAddress.addressType,
                primary: clonedAddress.addressLine1,
                secondary: clonedAddress.addressLine2,
                landmark: clonedAddress.landmark,
                cityName: clonedAddress.city,
                zipCode: clonedAddress.zipCode,
                countryCode: clonedAddress.country,
                state: clonedAddress.state,
            };

            this.addNewAddress(address);
        },
        logAddressList() {
        console.log("addressListDropdown00", this.addressListDropdown);
        },
        addNewAddress(address) {
            this.$store
                .dispatch("addAddress", address)
                .then((data) => {
                    this.formState = null;
                    let address = this.$store.getters.addressById(data.addedId);
                    this.filterAddresses();
                    this.selectAddress(address);
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
        nextStep() {

            if(this.currentStep == 1 && (this.addressListDropdown.primary == '' || this.destinationAddress.primary == '')){
                alert("Please fill all address fields!");
                return false;
            }

            if(this.currentStep == 2 && this.formData.collection.date == ''){
                alert("Please choose date first!");
                return false;
            }
            // this.$emit('triggerAddPackage');
            this.isNextStep = true;
            console.log("next step button")
            console.log("formData: ",this.formData);
            //  if(this.currentStep == 2){
            //     this.isNextStep = false;
            // }
            
            // if(this.currentStep == 1 && this.formData.collection.addressLine1 == ''){
            //     alert("Address required!");
            //     return true;
            // }

            if (this.currentStep < 3) {
                this.currentStep++;
            }
           

            //  if (!this.addressListDropdown.landmark) {
            //     alert('Landmark is required.');
            //     return;
            // }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                this.$nextTick(() => {
                if (this.currentStep == 1) { 
                    this.initCountryDropdown();
                }
            });
            }
        },
        handleStep2(){
            if(this.currentStep == 1 && (this.addressListDropdown.primary == '' || this.destinationAddress.primary == '')){
                alert("Please fill all address fields!");
                return false;
            }
            this.currentStep = 2;
        },
        handleStep3(){
            if(this.currentStep == 2 && this.formData.collection.date == ''){
                alert("Please choose date first!");
                return false;
            }
            if(this.currentStep == 1){
                return false;
            }
            this.handleStepClick(3)
        },

        handleStepClick(stepNumber) {
                
        this.currentStep = stepNumber;
        this.isNextStep = false;
    },
        showModal(action) {
            this.actionType = action;
            this.isModalVisible = true; // Show the modal
        },
        closeModal() {
            this.isModalVisible = false; // Hide the modal
        },
        handleModalAction() {
            this.closeModal();
            if (this.actionType === 'submitForm') {
                this.submitForm(); // Call your submitForm method
            } else if (this.actionType === 'submitAndAddAnother') {
                this.submitAndAddAnother(); // Call your submitAndAddAnother method
            }
        },
        submitForm() {
            console.log('Form submitted');
        },
        submitAndAddAnother() {
            console.log('Form submitted & adding another order');
        },
        book() {
      // document.querySelector('.booking').classList.add('loading');
      // this.$emit('setBookingLoader', true);
      this.error = null;


      const company_id = this.$store?.state?.quote?.deliveryService?.companyId ?? null;
      const insurancePrice = this.$store?.state?.quote?.deliveryService?.additionalCost;
      
      let bookingData = {
        // collectionDate: this.$store?.state?.booking?.data?.collectionTime?.date ?? '2024-09-17',
        collectionTime: this.selectedTime,
        coupon: this.formData.collection?.coupon ?? '',
        discount: this.discountAmount,
        collectionDate: this.formData?.collection?.date ?? '2024-09-17',
        collectionAddressId: this.$store?.state?.booking?.data?.collectionAddress ?? '123',
        // destinationAddressId: this.$store?.state?.booking?.data?.collectionAddress ?? '123',
        destinationAddressId: this.$store?.state?.booking?.data?.destinationAddress ?? '123',
        // selectedCompanyId: this.$store?.state?.booking?.data?.deliveryService ?? '19fe9abf-363e-11ec-b66b-f23c91aa6e6f',
        selectedCompanyId: company_id ?? this.$store?.state?.booking?.data?.deliveryService,
        packages: this.$store?.state?.booking?.data?.packages ?? '123',
        contactForInsurance: this.$store?.state?.booking?.data?.contactForInsurance ? "yes" : "no",
        successRedirectURL: window.location.origin + '/order-confirmation',
        failureRedirectURL: window.location.origin + '/order-failure',
        insurancePrice: insurancePrice > 0 ? insurancePrice : "NONE"
      };

      console.log("bookingData: ",bookingData);
      
      this.$store.dispatch('book', bookingData).then((data) => {
        // document.querySelector('.booking').classList.remove('loading');
        this.$emit('setBookingLoader', false);
        this.paymentURL = data.paymentURL;

        console.log("data: ",data);
        console.log("deliveryServicePrice: ",parseFloat(this.deliveryServicePrice));
        
        // this.total = data.total;
        this.total = parseFloat(this.deliveryServicePrice);
        
        this.$store.dispatch('readyForPayment', true);
      }).catch((error) => {
        this.$emit('setBookingLoader', false);
        this.error = error;
      });
    },
    },
};
</script>

<style lang="scss" scoped>
@import "../../scss/partials/variables.scss";


/** 1 **/

.form-style-1 .form {
    padding: 45px 0px 45px 0px;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -61px;
  /*position: relative;*/
  position: sticky;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.stepper-item .step-counter {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #F6F6F6;
  border: 2px solid #F6F6F6;
  font-weight: bold;
  color: #1985a1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: 1;
}

.stepper-item.completed .step-counter {
  border-color: #1985a1;
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
  font-weight: bold;
  color: #fff;
}

.stepper-item .step-name {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.stepper-item::before {
    content: '';
    position: absolute;
    top: 40%; /* Align the line with the center of the step counter */
    left: -50%;
    width: 100%;
    height: 2px;
    border-bottom: 2px dashed #ccc; /* Dashed border line */
    z-index: 0;
  }

// .stepper-item::before {
//  content: '';
//  position: absolute;
//  top: 20px;
//  left: -50%;
//  width: 100%;
//  height: 2px;
//  background-color: #ccc;
//  z-index: -1;
//}

.stepper-item:first-child::before {
  display: none;
}

.stepper-item.completed::before {
  // background-color: #007bff;
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
}

.booking .address-list-dropdown {
    width: 100% !important;
}

.booking .booking-form-section .inner-title-wrapper {
    padding: 18px 0px !important;
}

.container {
  max-width: 1000px;
  margin: auto;
}

.form-style-1 .input-field .half {
    width: 100%;
}

button.btn.btn-booking-bn.mt-2 {
    height: 37px;
    border-radius: 11px;
    box-shadow: 0 2px 20px 0 rgb(0 125 148 / 0%);
    // background-color: #e79f00;
    background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
    font-size: 10px;
    margin-left: 10px;
    line-height: 0px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    border: 0px none;
    min-width: 90px;
    padding: 0px 14px;
}

button.btn.btn-primary-next {
    padding: 0px 0px;
    min-width: 120px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
}

button.btn.btn-primary-next:hover {
    padding: 0px 0px;
    min-width: 120px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    color: #fff;
}

a.btn.btn-primary-next {
    padding: 15px 0px;
    min-width: 120px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    color: #fff;
}

a.btn.btn-primary-next:hover {
    padding: 15px 0px;
    min-width: 120px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    color: #fff;
}
a.btn.book {
    display: none !important;
}

button.btn.btn-primary-back {
    padding: 0px 0px;
    min-width: 120px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    background-color: #DDDDDD;
    border: 2px solid #DDDDDD;
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    color: #000 !important;
}

.title-button-times-label {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid #DDDDDD;
    top: 0;
    left: 0;
    font-family: arial;
    color: #000;
    background-color: #DDDDDD;
}

  /** 2 **/

  input[type="radio"] {
    opacity:0;
    width: 100%;
    height: 42px;
    background-color:blue;
    position:relative;
    z-index:1
  }
  group {
    width: 500px;
    display:flex;
  }
  .input-container {
    height:42px;
    width:100%;
    line-height:42px;
    text-align:center;
    position:relative;
    margin: 10px;
    &:first-child {
      label{
       border-radius: 5px 5px 5px 5px 
      }
    }
    &:last-child {
      label {
        border-radius: 5px 5px 5px 5px;
        border-right:2px solid #CCC;
      }
    }
  }
 
  input {
    &:checked + label {
      // background-color:#5AB8AB;  
      background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
      top:0;
      left:0;
      border:2px solid #1985a1 !important;
      z-index:2;
      color: white !important;
    }
  }

  .box-select{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem;
    gap: 1rem;
}
.select{
    border: 2px solid #e2e8ee;
    border-radius: 4px;
    padding: 8px 15px;
    background-color: #f9fafc;
    position: relative;
    font-size: 17px;
}
.select:after{
    content: '';
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #8c9ba2;
    top: 50%;
    right: 12px;
    rotate: 90deg;
    translate: 0 -50%;
    cursor: pointer;
} 
@media screen and (max-width: 800px){
    .box-select{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    padding: 10px 10px;

    }
    .select{
        width: 80%;
        padding: 8px 15px;
    }
}
@media screen and (max-width: 400px){
        .box-select{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    padding: 10px 10px;

    }
    .select{
        width: 130%;
        padding: 8px 5px;
    }
}
@media screen and (min-width: 1200px){
    .box-select{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 10px 10px;
}
    .select{
            width: 22%;
    }
}
  
/** 3 **/

.discount-total.mt-4 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.custom-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #187790;  /* Border color */
    border-radius: 4px;  /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    vertical-align: baseline;
}

/* Checkbox checked state */
.custom-checkbox:checked {
    background-color: #187790;  /* Fill color when checked */
    border-color: #187790;
}

/* Checkmark icon when checked */
.custom-checkbox:checked::after {
    content: '\2714';  /* Unicode checkmark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white !important;  /* Checkmark color */
}

/* Optional: Hover state */
.custom-checkbox:hover {
    border-color: #0c5d6e;
}

button.btn.btn-primary-other-order {
    padding: 0px 0px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    background-color: #4C5C68;
}
button.btn.btn-primary-other-order:hover {
    padding: 0px 0px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    background-color: #4C5C68;
    color: #fff !important;
}

.btn.btn-primary-other-order {
    height: 54px;
    margin-top: 19px;
    padding: 8px 16px; /* Updated padding for better button size */
    font-weight: 300;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0 2px 20px 0 rgba(0, 125, 148, 0);
    background-color: #4C5C68;
    color: white; /* Ensure text color is readable */
    text-decoration: none; /* Removes underline from router-link */
    display: inline-flex; /* Ensures the link behaves like a button */
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
}

.btn.btn-primary-other-order:hover {
    background-color: #3B4B54; /* Darker background on hover */
    color: #fff !important;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-image: url('../../assets/auth-bg2.png') !important;
    background: white;
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 5px;
    width: 700px;
    max-width: 90%;
}

.booking .payments {
    padding: 19px 10px 0px !important;
    border-radius: 22px !important;
    background-color: rgb(188 184 254 / 0%) !important;
    box-shadow: none !important;
    border-radius: 0px;
}

.booking .payments .btn.pay {
    height: 57px !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 20px 0 rgb(148 102 0 / 0%) !important;
    font-size: 14px !important;
    line-height: 58px !important;
    padding: 0px 45px !important;
}

/**/
.time-slots-container {
    display: flex;
    align-items: center; /* Vertically center items */
    gap: 10px; /* Space between each time slot */
    margin-left: 10px; /* Add some space from the left */
}

/* Common styles for slot options */
.slot-option {
    display: flex;
    align-items: center; /* Center items vertically */
}

/* Styling for radio buttons */
.slot-option input[type="radio"] {
    margin-right: 5px;
}

/* Label styling */
.slot-option label {
    font-family: Arial, sans-serif;
    color: #333;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Checked state for morning slots */
.morning-slots input:checked + label {
    background-color: #E0F7FA; /* Light cyan */
    border-color: #00ACC1; /* Cyan border */
}

/* Checked state for afternoon slots */
.afternoon-slots input:checked + label {
    background-color: #FFF3E0; /* Light orange */
    border-color: #FFB300; /* Orange border */
}

/* Hover effects for all slots */
.slot-option label:hover {
    background-color: #F0F0F0; /* Light gray on hover */
}

    // Flat picker styling 


</style>
<style >
    .flatpickr-calendar .flatpickr-day.today {
        background-color: #1985a1 !important;
        border-color: #1985a1 !important;
        color: #FFF;
    }
    #booking_collectionAddress input#dob{
        border: 1px solid rgb(46 46 46 / 84%);
        height: 39px;
        margin-top: 10px;
        position: relative;
        z-index: 2;
        background: transparent;
    }

    .booking .delivery-service .the-form .btn.save{
        background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);
        box-shadow:none !important;
    }

    .booking .booking-form-section .inner-title-wrapper .actions .btn{
        background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);
        box-shadow:none !important;
    }

    .booking .booking-form-section .inner-title-wrapper .title:before{
        background-color: rgb(25, 133, 161) !important;
    }

    /* Date-icon  */

    .form-style-1 .custom-date-icon {
            display: flex;
        align-items: center;
        background-color: white;
    }

    .form-style-1 .custom-date-icon svg{
        position: relative;
        right: 30px;
        top: 5px;
        cursor: pointer;
        z-index: 1;
    }

    a.add-new {
        display: flex;
        height: 50px;
        margin-top: 25px;
    }

    #booking_collectionAddress .addresses {
        display: flex;
        justify-content: end;
        flex-grow: 3;

    }
    #booking_collectionAddress .address-form{
        width: 100%;
    }

    .booking .box{
        background-color: transparent !important;
    }
</style>