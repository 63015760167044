<template>
  <h5
    style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;"
  >Get a Quote</h5>
  <div class="quote-n-book-widget-container"></div>
  <div class="quote-contact-form" ref="quoteForm">
    <div class="form-outer-wrapper form-style-1" :class="formState">
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.observeElement();
  },
  methods: {
    observeElement() {
      const target = this.$refs.quoteForm;
      if (target) {
        if (window.qnbScriptAdded) { 
          location.reload();
        }
        if (!window.qnbScriptAdded) {
          window.qnbScriptAdded = true;
          window.qnbwe = this.expanded;
          window.userApp = true;

          // Remove any existing script with the same src
          const existingScript = document.querySelector(
            `script[src*="widget_new.js.php"]`
          );
          if (existingScript) {
            document.head.removeChild(existingScript);
          }

          var j = document.createElement("script");
          j.src =
            process.env.VUE_APP_MARKETING_CDN_SITE_URL +
            "quote-n-book/widget_new.js.php?ver=" +
            Date.now();
          j.async = true;
          document.head.appendChild(j);
        }
      }
    }
  }
};
</script>